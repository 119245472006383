$radio-button: 'radio-button';

.#{$radio-button} {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  input[type="radio"] {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    outline: none !important;
    -webkit-appearance: none;
    margin-right: 1rem;
    cursor: pointer;

    &::before {
      content: "";
      position: relative;
      top: 0;
      left: 0;
      display: block;
      background: #000;
      border: 1.75px solid #000;
      border-radius: 100%;
      box-shadow: none;
      width: 20px;
      height: 20px;
      transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:checked::before {
      background-color: yellow;
    }

    &:disabled::before {
      cursor: not-allowed;
      background-color: #bfbfbf;
      border-color: #bfbfbf;
    }
  }
}
