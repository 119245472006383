$coin: 'coin';
$coin-form: 'coin-form';
$coin-menu: 'coin-menu';
$coin-guide: 'coin-guide';
$coin-faq: 'coin-faq';

.#{$coin} {
    padding-top: 2rem;
    padding-bottom: 4rem;
    &__top {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 1.25rem;
        color: #324f65;
        font-weight: bold;
        @media screen and (max-width: 480px) {
            font-size: 11px;
            line-height: 16px;
        }
        @media screen and (max-width: 320px) {
            font-size: 10px;
        }
        span {
            padding: 0 .25rem;
        }
        img {
            height: 22px;
            margin-right: .25rem;
            @media screen and (max-width: 480px) {
                height: 16px;
            }
        }
    }
    &__bot {
        display: grid;
        grid-template-columns: 40% 60%;
        @media screen and (max-width: 767px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__left {
        @media screen and (max-width: 767px) {
            order: 2;
            width: 100%;
        }
    }
    &__right {
        margin-left: 1.25rem;
        @media screen and (max-width: 767px) {
            order: 1;
            margin-left: 0;
            width: 100%;
        }
    }
    .table {
        background-color: #fff;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        margin-bottom: 0;
        thead {
            th {
                background: linear-gradient(180deg, rgba(60,95,121,1) 0%, rgba(40,62,79,1) 100%);
                color: #fff;
                font-size: 20px;
                line-height: 22px;
                padding: 1rem .5rem;
                border: 0;
                @media screen and (max-width: 980px) {
                    font-size: 14px;
                    line-height: 18px;
                }
                img {
                    height: 22px;
                }
            }
        }
        tbody {
            border: 1px solid #b4b4b4;
            box-shadow: inset -1px 0px 1px #b4b4b4;
        }
        th, td {
            border-color: #b4b4b4;
        }
    }
    &-rate {
        margin-bottom: 1.25rem;
        .table {
            thead {
                th {
                    text-align: center;
                }
            }
            td {
                padding: .75rem .5rem;
                img {
                    height: 22px;
                }
            }
            p {
                display: flex;
                align-items: center;
                img {
                    margin-right: .25rem;
                }
            }
            .up, .down {
                img {
                    height: 10px;
                }
            }
            strong {
                line-height: 22px;
                font-size: 18px;
                @media (max-width: 1200px) and (min-width: 981px) {
                    font-size: 14px;
                    line-height: 20px;
                }
                @media (max-width: 980px) and (min-width: 768px) {
                    font-size: 10px;
                    line-height: 16px;
                }
                @media screen and (max-width: 767px) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }
    &-history {
        margin-bottom: 1.25rem;
        .table {
            @media (max-width: 1200px) and (min-width: 981px) {
                font-size: 14px;
                line-height: 20px;
            }
            @media (max-width: 980px) and (min-width: 768px) {
                font-size: 10px;
                line-height: 16px;
            }
            @media screen and (max-width: 767px) {
                font-size: 12px;
                line-height: 18px;
            }
            tbody {
                th, td {
                    padding: .5rem;
                }
                th {
                    border-bottom: 2px solid #b4b4b4;
                }
            }
            tr {
                th, td {
                    text-align: center;
                    &:first-child {
                        text-align: left;
                    }
                }
            }
        }
    }
}

.#{$coin-menu} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .nav {
        align-items: flex-end;
    }
    .nav-link {
        font-size: 23px;
        line-height: 26px;
        font-weight: 600;
        padding: .5rem 2rem;
        display: flex;
        align-items: center;
        @media screen and (max-width: 480px) {
            font-size: 14px;
            line-height: 16px;
            padding: .5rem 1rem;
        }
        &.active {
            height: 60px;
            @media screen and (max-width: 480px) {
                height: 40px;
            }
        }
    }
    .nav-item {
        border-radius: 3px 3px 0 0;
        margin-right: 1px;
        &:first-child {
            background: linear-gradient(180deg, rgba(206,86,100,1) 0%, rgba(205,70,86,1) 100%);
        }
        &:last-child {
            margin-right: 0;
            background: linear-gradient(180deg, rgba(77,208,111,1) 0%, rgba(51,152,78,1) 100%);
        }
    }
    &__select {
        background: linear-gradient(180deg, #3c5f79 0%, #283e4f 100%);
        margin-bottom: .25rem;
        min-width: 120px;
        select {
            color: #fff;
        }
    }
}

.#{$coin-form} {
    background-color: #fff;
    color: #3c516c;
    padding: 1.5rem 2.5rem;
    font-size: 15px;
    @media screen and (max-width: 767px) {
        padding: 1rem;
    }
    .form-control {
        border: 1px solid #959595;
        background-color: #f1f1f1;
        font-size: 15px;
        line-height: 18px;
        height: 45px;
        font-weight: bold;
        &::placeholder {
            color: #949494;
        }
    }
    .col-form-label {
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .form-group {
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
        &.error {
            .col-form-label {
                color: #c63030;
            }
            .unit {
                border-left-color: #c63030;
                background-color: #e8caca;
            }
            .form-control {
                border-color: #c63030;
                box-shadow: 0 0 5px #c63030;
            }
        }
    }
    .col-sm-8 {
        position: relative;
    }
    .unit {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 16px;
        top: 1px;
        height: 43px;
        width: 90px;
        border-left: 1px solid #959595;
        border-radius: 0 3px 3px 0;
    }
    .select {
        display: inline-block;
        background: linear-gradient(180deg, #3c5f79 0%, #283e4f 100%);
        height: 45px;
        min-width: 170px;
        select {
            color: #fff;
        }
    }
    .btn {
        margin-left: auto;
        color: #fff;
        background: linear-gradient(180deg, #ce5664 0%, #cd4656 100%);
        padding: .75rem 2rem;
        display: block;
        font-size: 17px;
        line-height: 20px;
        transition: all .3s linear;
        &:hover {
            filter: brightness(1.2);
        }
    }
}

.#{$coin-guide} {
    font-size: 16px;
    line-height: 22px;
    margin-top: .5rem;
    em {
        display: block;
        margin-bottom: 1rem;
    }
    ul {
        margin-bottom: .5rem;
        list-style-type: none;
        li {
            position: relative;
            &:before {
                content: "";
                display: inline-block;
                height: 8px;
                width: 8px;
                background-color: #3c3c3c;
                position: absolute;
                left: -1.25rem;
                top: 6px;
                transform: rotate(45deg);
            }
        }
    }
    h5 {
        margin-bottom: .25rem;
    }
}

.#{$coin-faq} {
    img {
        height: 22px;
        margin-right: .25rem;
    }
    a {
        display: flex;
        align-items: center;
        font-size: 19px;
        line-height: 22px;
        color: #2b4456;
        &:hover {
            color: #e4af37;
        }
    }
    .active {
        color: #4788ff;
    }
}
