html {
  &.no-scroll {
    overflow: hidden;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  outline: none;
}

a {
  color: #fff;
  &:hover {
    text-decoration: none;
    color: #e4af37;
  }
}

img {
  max-width: 100%;
}
