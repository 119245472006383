$footer: 'footer';

.#{$footer} {
    background: linear-gradient(180deg, rgba(60,95,121,1) 0%, rgba(40,62,79,1) 100%);
    padding: 1.25rem 0;
    color: #fff;
    &__content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 4rem;
        align-items: flex-end;
    }
    a {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: .25rem;
        &:hover {
            i {
                border-color: transparent #e4af37;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &.active {
            color: #e4af37;
            i {
                border-color: transparent #e4af37;
            }
        }
    }
    &__title {
        font-size: 26px;
        line-height: 34px;
        border-bottom: 1px solid #fff;
        margin-bottom: .5rem;
    }
    &__link {
        i {
            border-color: transparent #fff;
            border-style: solid;
            border-width: 8px 0 8px 12px;
            height: 0;
            width: 0;
            margin-right: .5rem;
        }
    }
    @media screen and (max-width: 980px) {
        a {
            font-size: 14px;
        }
        &__content {
            display: block;
            margin-left: 2rem;
        }
        &__title {
            display: inline-block;
        }
        &__item {
            margin-bottom: 1.5rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
