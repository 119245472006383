$checkbox: 'checkbox';

.#{$checkbox} {
  position: absolute;
  opacity: 0;

  & + label {
    display: flex;
    align-items: center;
    font-size: 10.5px;
    font-weight: $light;
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    background-color: transparent;
  }

  //&:hover + label:before {
  //  background: #000;
  //}
  //&:focus + label:before {
  //  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  //}
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background-color: #000;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #000,
    4px 0 0 #000,
    4px -2px 0 #000,
    4px -4px 0 #000,
    4px -6px 0 #000,
    4px -8px 0 #000;
    transform: rotate(45deg);
  }
}
