$container: 'container';

.#{$container} {
  max-width: $container-width !important;

  .adaptive & {
    max-width: none !important;
    width: $container-width;
  }

  .adaptive.mobile & {
    max-width: none !important;
    width: 100%;
  }
}
