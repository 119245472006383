$select: 'select';

.#{$select} {
  user-select: none;
  overflow: hidden;
  border: 1px solid #959595;
  padding: .5rem 0;
  position: relative;
  border-radius: 4px;
  font-size: 15px;
  line-height: 18px;

  &:after {
    content: '';
    position: absolute;
    border-top: 8px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: none;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
  }

  select {
    appearance: none;
    position: relative;
    background: transparent;
    display: block;
    width: 100%;
    outline: none;
    cursor: pointer;
    z-index: 2;
    border: none;
    text-overflow: '';
    text-indent: .5rem;
    font-size: 11.9px;
    text-transform: uppercase;
    height: 100%;
    padding-left: .75rem;
    padding-right: 3rem;

    option[disabled] {
      color: #eeeeee;
      text-shadow: none;
      border: none;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #888888;
    }

    &::-ms-expand {
      display: none;
    }
  }
}
