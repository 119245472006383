$faq: 'faq';

.#{$faq} {
    @media screen and (max-width: 767px) {
        margin-bottom: 1.25rem;
    }
    &__hdg {
        background: #e2e2e2;
        font-size: 33px;
        line-height: 36px;
        padding: 1rem;
        border-radius: 4px 4px 0 0;
        border: 1px solid #b4b4b4;
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
    &__content {
        border: 1px solid #b4b4b4;
        border-top: 0;
        padding: 1.5rem 1rem;
        min-height: 600px;
        background-color: #fff;
        h5 {
            margin-bottom: 1rem;
        }
        a {
            display: flex;
            color: #3c3c3c;
            span {
                width: 30px;
            }
            p {
                width: calc(100% - 30px);
            }
            &:hover {
                color: #4788ff;
            }
        }
    }
}
