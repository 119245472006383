$menu: 'menu';

.#{$menu} {
    display: flex;
    font-size: 13px;
    line-height: 22px;
    img {
        height: 22px;
        width: auto;
    }
    &__item {
        margin-right: 1.5rem;
        i {
            display: inline-block;
            border-color: #fff transparent;
            border-style: solid;
            border-width: 8px 5px 0 5px;
            height: 0;
            width: 0;
            margin-left: 4px;
        }
        a {
            &:hover {
                i {
                    border-color: #e4af37 transparent;
                }
            }
        }
        .dropdown-menu {
            padding: 0;
            border: 1px solid #c6c6c6;
            border-radius: 0;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            li {
                &:last-child {
                    .dropdown-item {
                        border-bottom: 0;
                    }
                }
            }
            .dropdown-submenu {
                position: relative;
                i {
                    position: absolute;
                    border-color: transparent #345369;
                    border-style: solid;
                    border-width: 4px 6px 4px 0px;
                    height: 0;
                    width: 0;
                    margin: 0;
                    left: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover {
                    .dropdown-menu {
                        display: block;
                        right: 100%;
                        left: unset;
                        top: -1px;
                        margin: 0;
                    }
                }
            }
        }
        .dropdown-item {
            border-bottom: 1px solid #c6c6c6;
            padding: .5rem;
        }
    }
    @media screen and (max-width: 767px) {
        font-size: 8px;
        line-height: 12px;
        img {
            height: 12px;
            @media screen and (max-width: 320px) {
                display: none;
            }
        }
        &__item {
            margin-right: .5rem;
            i {
                border-width: 4px 2px 0 2px;
                margin-left: 0;
            }
        }
    }
}
