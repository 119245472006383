$header: 'header';

.#{$header} {
    background: linear-gradient(180deg, rgba(60,95,121,1) 0%, rgba(40,62,79,1) 100%);
    padding: .5rem 0;
    &__logo {
        img {
            height: 52px;
            width: auto;
        }
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    @media screen and (max-width: 767px) {
        &__logo {
            font-size: 0;
            img {
                height: 28px;
            }
        }
    }
}
