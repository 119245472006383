@font-face {
    font-family: 'BeVietnam';
    src: url('#{$font-path}BeVietnam/BeVietnam-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'BeVietnam';
    src: url('#{$font-path}BeVietnam/BeVietnam-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'BeVietnam';
    src: url('#{$font-path}BeVietnam/BeVietnam-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
