$service: 'service';

.#{$service} {
    &__hdg {
        background: linear-gradient(180deg, #3c5f79 0%, #283e4f 100%);
        color: #fff;
        font-size: 20px;
        line-height: 22px;
        padding: 1rem;
        border-radius: 4px 4px 0 0;
    }
    &__content {
        border: 1px solid #b4b4b4;
        padding: .5rem;
        background-color: #fff;
        font-size: 14px;
        line-height: 20px;
        min-height: 600px;
        position: relative;
    }
    &__wrap-item {
        margin-bottom: 4rem;
    }
    &__item {
        border: 1px solid #b4b4b4;
        position: relative;
        margin-bottom: 1rem;
        padding: .5rem 1.25rem;
        &:last-child {
            margin-bottom: 0;
        }
        &:before {
            content: "";
            background-color: #fdac4d;
            height: calc(100% + 2px);
            width: 3px;
            position: absolute;
            left: -1px;
            top: -1px;
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
    }
    &__pagination {
        position: absolute;
        bottom: 0;
        .pagination {
            margin-bottom: .5rem;
        }
        .active {
            .page-link {
                background-color: #fdac4d;
                border-color: #fdac4d;
            }
        }
        .page-link {
            color: #2c4658;
            border-color: #c6c6c6;
            border-radius: 0 !important;
        }
    }
    .phone {
        color: #4788ff;
        position: relative;
        display: block;
        &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #33984e;
            position: absolute;
            left: -10px;
            top: 7px;
        }
    }
}
