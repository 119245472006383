//PHẦN NÀY SẼ CONFIG NHỮNG BIẾN CÓ SẴN CỦA BOOSTRAP HOẶC THÊM NHỮNG BIẾN MỚI TỰ TẠO

//Images path and fonts path
$image-path: '../images/';
$font-path: '../fonts/';
$fa-font-path: '../fonts/fa/';
//=====================================
//Override color of Bootstrap
$body-color: #3c3c3c;
$body-bg: #efefe7;
//=====================================
//Override spacer of Bootstrap
$spacer: 1rem !default;
$spacers: (
        0: 0,
        0-half: ($spacer * 0 + 0.5),
        1: ($spacer * 1),
        1-half: ($spacer * 1 + 0.5),
        2: ($spacer * 2),
        2-half: ($spacer * 2 + 0.5),
        3: ($spacer * 3),
        3-half: ($spacer * 3 + 0.5),
        4: ($spacer * 4),
        4-half: ($spacer * 4 + 0.5),
        5: ($spacer * 5),
        5-half: ($spacer * 5 + 0.5),
        6: ($spacer * 6),
        6-half: ($spacer * 6 + 0.5),
        7: ($spacer * 7),
        7-half: ($spacer * 7 + 0.5),
        8: ($spacer * 8),
        8-half: ($spacer * 8 + 0.5),
        9: ($spacer * 9),
        9-half: ($spacer * 9 + 0.5),
        10: ($spacer * 10),
        10-half: ($spacer * 10 + 0.5)
);
//=====================================
//Container width của trang
$container-width: 1200px;
$page-width-mobile: 640px;
//=====================================
//Override breakpoint of Bootstrap
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);
//=====================================
//Override font weight of Boostrap
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$headings-font-weight: 800;
//Font-weight-base
$font-weight-base: $font-weight-normal;
//=====================================
//Font
$font-size-base: 1rem;
$main-font: Arial, sans-serif;
$second-font: Arial, sans-serif;
$third-font: Arial, sans-serif;
//Font-family-base
$font-family-base: $main-font;
//=====================================
//Override line-height of Bootstrap
$line-height-base: 1.5;
//=====================================
//Override <p> margin bottom
$paragraph-margin-bottom: 0;
//=====================================
//Override h1, h2, h3, h4, h5, h6 margin bottom
$headings-margin-bottom: 0;
//=====================================
//Override <label> margin bottom
$label-margin-bottom: 0;
