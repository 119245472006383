$banner: 'banner';

.#{$banner} {
    background: url("#{$image-path}banner-main.jpg") no-repeat center;
    background-size: cover;
    color: #fff;
    text-transform: uppercase;
    padding-top: 5rem;
    padding-bottom: 1rem;
    &-title {
        font-size: 32px;
        line-height: 40px;
        margin-left: 15rem;
        position: relative;
        padding-left: 1rem;
        margin-bottom: 4rem;
        &:before {
            content: "";
            height: calc(100% - 16px);
            width: 6px;
            background-color: #e4af37;
            position: absolute;
            left: 0;
            top: 8px;
        }
        span {
            color: #e4af37;
        }
    }
    &-trade {
        display: inline-block;
        text-align: center;
        &__hdg {
            font-size: 36px;
            line-height: 42px;
            margin-bottom: .5rem;
        }
        &__wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 4rem;
            margin-bottom: .5rem;
            padding: 0 1.5rem;
        }
        &__link {
            font-size: 18px;
            line-height: 28px;
            font-weight: bold;
            text-decoration: underline;
        }
        &__item {
            font-size: 16px;
            line-height: 22px;
            padding: .5rem 2rem;
            border-radius: 5px;
            position: relative;
            overflow: hidden;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: -75%;
                z-index: 2;
                display: block;
                width: 50%;
                height: 100%;
                background: linear-gradient( 90deg ,hsla(0,0%,100%,0),hsla(0,0%,100%,.2));
                transform: skewX(-35deg);
                animation: shine 2s infinite;
            }
            b {
                display: block;
            }
            strong {
                display: block;
                margin-left: 30px;
            }
            img {
                width: 22px;
                height: 22px;
                margin-right: .5rem;
            }
            &--buy {
                background-color: #36566d;
                strong {
                    color: #18ac00;
                }
            }
            &--sell {
                background-color: #251f34;
                &:after {
                    animation: shine 1.5s infinite;
                    animation-delay: 2s;
                }
                strong {
                    color: #b30202;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        &-title {
            margin-left: 0;
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 2rem;
            &:before {
                height: calc(100% - 10px);
                top: 5px;
            }
        }
        &-trade {
            display: block;
            &__hdg {
                font-size: 18px;
                line-height: 22px;
            }
            &__wrap {
                padding: 0;
                display: block;
            }
            &__item {
                font-size: 14px;
                line-height: 18px;
                padding: 1rem;
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
